import {getElementOffset} from "../utils/helpers";

export const scrollLinks = () => {
  const links = document.querySelectorAll('.js-scroll-link')
  if (!links.length) return

  const $header = document.querySelector('.header')

  links.forEach($link => {
    const $targetSection = document.querySelector($link.getAttribute('href'))

    if ($targetSection) {
      const targetSectionRect = getElementOffset($targetSection)

      $link.addEventListener('click', (event) => {
        event.preventDefault()

        window.scrollTo({
          behavior: 'smooth',
          top: targetSectionRect.dom.top
        })
      })
    }
  })
}
