import gsap, {Linear} from 'gsap'

export const conferenceAnimation = () => {
  const sections = document.querySelectorAll('.conferences')
  if (!sections.length) return

  // === System variables
  let scrollPosition = 0
  let scrollDir = null

  // === Methods
  const checkScrollDirection = () => {
    if (window.scrollY < scrollPosition) {
      scrollDir = 'up'
    } else {
      scrollDir = 'down'
    }
    scrollPosition = window.scrollY
  }

  const cleanStyles = () => {
    if (window.innerWidth <= 992) {
      sections.forEach($section => {
        const $sectionBody = $section.querySelector('.conferences__body')
        $sectionBody.removeAttribute('style')
      })
    }
  }

  const singleSectionHandle = ($section) => {
    const $sectionBody = $section.querySelector('.conferences__body')

    // Rect values
    const sectionRect = $section.getBoundingClientRect()

    if (sectionRect.bottom <= 0 || sectionRect.top >= window.innerHeight) return

    const translateValue = (sectionRect.top + (sectionRect.height / 4)) * .25

    gsap.to($sectionBody, {
      y: translateValue,
      ease: Linear.easeNone
    })

    // gsap.to($sectionBody, {
    //   y: scrollDir === 'up' ? 20 : -20,
    //   duration: .4,
    //   ease: Linear.easeNone,
    //   onComplete: () => {
    //     gsap.to($sectionBody, {
    //       y: 0,
    //       duration: .3,
    //     })
    //   }
    // })
  }

  const scrollHandle = () => {
    if (window.innerWidth <= 992) return

    checkScrollDirection()
    sections.forEach(singleSectionHandle)
  }

  // === Listeners
  // Scroll
  window.addEventListener('scroll', scrollHandle)

  // Resize
  window.addEventListener('resize', cleanStyles)
}
