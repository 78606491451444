const burger = () => {
  const burgerIcon = document.querySelector('.icon-menu');
  const burgerMenu = document.querySelector('.nav');
  burgerIcon.addEventListener('click', () => {
    burgerMenu.classList.toggle('_active');
    burgerIcon.classList.toggle('_active');
  });
};

export { burger };
