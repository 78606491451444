function form() {
  const btnSend = document.querySelector('.modal__form-submit');
  const modalForm = document.querySelector('.modal__form');
  const isHe = document.documentElement.dir = 'rtl'

  const markupEN = `<div class="modal__after-send">
      <h4 class="modal__after-send-title">Your details have been successfully received. We will do our best to contact you as soon as possible :)</h4>
      <p class="modal__after-send-text">Best regards, DenTech Solutions team</p>
    </div>`;

  const markupHE = `<div class="modal__after-send">
      <h4 class="modal__after-send-title">פרטייך התקבלו בהצלחה. אנו נעשה את מירב מאמצינו בכדי ליצור איתך קשר בהקדם האפשרי :) </h4>
      <p class="modal__after-send-text">בברכה, צוות דנין טכנולוגיות</p>
    </div>`;

  if (btnSend && modalForm) {
    btnSend.addEventListener('click', (e) => {
      e.preventDefault();

      // Скрыть все дочерние элементы формы
      for (let i = 0; i < modalForm.children.length; i++) {
        modalForm.children[i].style.display = 'none';
      }
      modalForm.innerHTML = isHe ? markupHE : markupEN;
    });
  }
}

export { form };
