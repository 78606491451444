// import { select } from './components/select';
// import { accordion } from './components/accordion';
import { header } from './modules/header';
import { formValidation } from './components/form-validation';
import { modal } from './components/modal';
import { burger } from './modules/burger';
import { adjustHeight } from './modules/textareaHeight';
import { form } from './modules/form';
import {scrollLinks} from "./components/scroll-links";
import {bgVideos} from "./components/bg-video";
import {conferenceAnimation} from "./animation/conference-animation";
import {browserDetect} from "./utils/helpers";
import {setConferenceVars} from "./modules/set-conference-vars";
import {heroAnimation} from "./animation/hero-animation";

window.addEventListener('load', () => {
  // Show hidden elements
  document.querySelectorAll('.important_none')?.forEach(($el) => $el.classList.remove('important_none'));

  // Browser client
  const browserClient = browserDetect()

  // Change direction
  if(document.documentElement.lang === 'en') {
    document.documentElement.dir  = 'ltr'

    setTimeout(() => {
      document.documentElement.dir  = 'ltr'
    }, 100)
  }

  // ===== Components
  // All selects
  // select();

  // All accordions
  // accordion();

  // Scroll links
  scrollLinks()

  // Videos
  bgVideos()

  // Forms
  formValidation();

  // Modals
  modal();

  form();
  const textarea = document.querySelector('.modal__input--textarea');
  textarea.addEventListener('input', adjustHeight);

  // ===== Components end

  // ===== Modules
  // Header
  const headerInit = header();

  // Burger
  burger();

  // Set conference variables
  setConferenceVars(browserClient)
  // ===== Modules end

  // ===== Animation
  // Hero
  heroAnimation()

  // Conference animation
  conferenceAnimation()
  // ===== Animation end

  // ===== Window listeners
  // Scroll
  window.addEventListener('scroll', (event) => {
    headerInit?.scrollHandle();
  });

  // Resize
  window.addEventListener('resize', (event) => {
    headerInit?.setCssVars();
  });
  // ===== Window listeners end
});
