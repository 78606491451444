import gsap from 'gsap'

export const heroAnimation = () => {
  const $section = document.querySelector('.hero')
  if (!$section) return

  // === System variables

  // === Elements
  const videos = $section.querySelectorAll('.hero__video')
  const $title = $section.querySelector('.hero__title')
  const $buttons = $section.querySelector('.hero__buttons')

  // === Gsap
  // Timeline
  const tl = gsap.timeline()

  // Options
  const gsapOptions = {opacity: 1}

  // Init
  tl.to($title, gsapOptions)
  tl.to($buttons, gsapOptions)
  videos.length && videos.forEach($video => tl.to($video, gsapOptions))
}
