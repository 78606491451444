import {debounce} from "../utils/helpers";

export const bgVideos = () => {
  const videos = document.querySelectorAll('.js-bg-video')
  if (!videos.length) return

  // Methods
  function togglePlayingOnVisibleState() {
    videos.forEach(($video) => {
      const videoRect = $video.getBoundingClientRect()

      if (videoRect.top > window.innerHeight || videoRect.bottom < 0) {
        $video.pause()
      } else {
        $video.play()
      }
    })
  }

  // Init
  togglePlayingOnVisibleState()

  // Listeners
  window.addEventListener('scroll', debounce(togglePlayingOnVisibleState, 200))
}
