export const setConferenceVars = (browserClient) => {
  if(!browserClient) return

  const rootStyle = document.documentElement.style

  if(browserClient === 'safari') {
    rootStyle.setProperty('--cnfrnc-cover-opacity', '0')
    rootStyle.setProperty('--cnfrnc-cover-blend-mode', 'none')
  }
}
